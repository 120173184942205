
export const TitleList = [ "订单号","收入来源","结算金额(元)" ]


export const typeList = {
    "1":"代取类型",
    "2":"万能互助",
    "3":"审核提现失败",
    "4":"审核提现",
}
