import Storage from "@/util/Storage";
import { BillRunning } from '@/Api/Money';

// 账单流水
export function getBillSuc(id:string|number) {
    const userId = Storage.GetData_ && Storage.GetData_("userId")
    const token = Storage.GetData_ && Storage.GetData_("token")
    return Promise.resolve(BillRunning({ userId,token },{ id }).then(res=>{
        return res.data
    }))
}
