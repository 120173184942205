


import { Module,VuexModule,getModule,Mutation,Action } from "vuex-module-decorators"
import store from "@/store/index"
import BillSucStoreType from "@/views/Money/BillSuc/Store/indexType";

@Module({
    name:"BillSuc",
    store,
    dynamic: true,
})
export default class BillSuc extends VuexModule implements BillSucStoreType{
    BillSucStoreListNum = 0
    get getBillSucStoreListNum(){ return this.BillSucStoreListNum }

    @Mutation
    SetBillSucStoreListNum(data: number): void {
        this.BillSucStoreListNum = data
    }

}

export const BillSucStore = getModule( BillSuc )
